/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.0
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinkedFi } from './linkedFi';
import { ShippingAddress } from './shippingAddress';

/**
 * User details
 */
export interface UserProfile {
  /**
   * First name of the user
   */
  first_name: string;
  /**
   * Last name of the user
   */
  last_name: string;
  /**
   * Middle name of the user
   */
  middle_name?: string;
  /**
   * Email addresses of the user
   */
  email: string;
  /**
   * Local-part (prefix) of the email ID is masked using asterisk except first and last characters
   */
  email_masked?: string;
  /**
   * When the profile has the email received from FI. THEN the \"email_verified_by\" must state \"FI\" When the profile has the email verified by KONEK. THEN the \"email_verified_by\" must state \"KONEK\"
   */
  email_verified_by?: UserProfile.EmailVerifiedByEnum;
  /**
   * Specify a valid phone number
   */
  phone_number?: string;
  /**
   * Last 4 digits of the phone number.
   */
  phone_masked?: string;
  /**
   * Specifies `shipping_address_ref` of default shipping address
   */
  default_shipping_address_ref?: string;
  /**
   * Specifies `fi_account_ref` of default account
   */
  default_fi_account_ref?: string;
  /**
   * Unique identifier of the linked Fi
   */
  default_linked_fi_id?: string;
  /**
   * List of shipping addresses
   */
  shipping_addresses?: Array<ShippingAddress>;
  /**
   * List of linked Fi\'s and thier accounts
   */
  linked_fi_info?: Array<LinkedFi>;
}
export namespace UserProfile {
  export type EmailVerifiedByEnum = 'FI' | 'KONEK';
  export const EmailVerifiedByEnum = {
    Fi: 'FI' as EmailVerifiedByEnum,
    Konek: 'KONEK' as EmailVerifiedByEnum,
  };
}
