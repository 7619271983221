/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.0
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies the frequencies and limits for the recurring order and needs to be present if the `item_payment_type = RECURRING`
 */
export interface RecurringInfo {
  /**
   * Specifies the max amount the customer can be charged per billing cycle for RECURRING. One of `amount_capped` and `amount_variance` must be specified. If `amount_capped` is specified:   * The value must be greater than `amount` value   * The value in `amount_variance` is ignored
   */
  amount_capped?: number;
  /**
   * Specifies in % that the accepted variance in the amount per billing cycle for RECURRING. One of `amount_capped` and `amount_variance` must be specified. If `amount_capped` is specified this value is ignored.
   */
  amount_variance?: number;
  /**
   * Day of month that the RECURRING payment occurs on. Required if both:   * `frequency_type` is `MONTH`   * `day_of_week` or `week_of_month` is not specified Allowed values are from 1 to 31, if a month has less then provided days then the payment will be accepted on the last day of the month.
   */
  day_of_month?: number;
  /**
   * Day of week that the RECURRING payment occurs on. Required if either   * `frequency_type` is `WEEK`   * `frequency_type` is `MONTH` and `day_of_month` is not specified
   */
  day_of_week?: RecurringInfo.DayOfWeekEnum;
  /**
   * End date of the RECURRING payment.
   */
  end_date?: string;
  /**
   * The waiting interval between payments for the RECURRING transactions
   */
  frequency_rate?: number;
  /**
   * Frequency of the payments in the billing cycle for RECURRING.
   */
  frequency_type?: RecurringInfo.FrequencyTypeEnum;
  /**
   * Total number of payments in the RECURRING setup. Required if `end_date` is not specified.
   */
  max_number_of_payments?: number;
  /**
   * Start date of the RECURRING payment. Defaults to the current time if left blank.
   */
  start_date?: string;
  /**
   * Week of month that the RECURRING payment occurs on. Required if both:   * `frequency_type` is `MONTH`   * `day_of_week` is specified and `day_of_month` is not specified
   */
  week_of_month?: RecurringInfo.WeekOfMonthEnum;
}
export namespace RecurringInfo {
  export type DayOfWeekEnum = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
  export const DayOfWeekEnum = {
    Sun: 'SUN' as DayOfWeekEnum,
    Mon: 'MON' as DayOfWeekEnum,
    Tue: 'TUE' as DayOfWeekEnum,
    Wed: 'WED' as DayOfWeekEnum,
    Thu: 'THU' as DayOfWeekEnum,
    Fri: 'FRI' as DayOfWeekEnum,
    Sat: 'SAT' as DayOfWeekEnum,
  };
  export type FrequencyTypeEnum = 'DAY' | 'WEEK' | 'MONTH';
  export const FrequencyTypeEnum = {
    Day: 'DAY' as FrequencyTypeEnum,
    Week: 'WEEK' as FrequencyTypeEnum,
    Month: 'MONTH' as FrequencyTypeEnum,
  };
  export type WeekOfMonthEnum = 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH' | 'LAST';
  export const WeekOfMonthEnum = {
    First: 'FIRST' as WeekOfMonthEnum,
    Second: 'SECOND' as WeekOfMonthEnum,
    Third: 'THIRD' as WeekOfMonthEnum,
    Fourth: 'FOURTH' as WeekOfMonthEnum,
    Last: 'LAST' as WeekOfMonthEnum,
  };
}
