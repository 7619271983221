/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.0
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { ConsentCancelRequest } from '../model/consentCancelRequest';
// @ts-ignore
import { ConsentCreateRequest } from '../model/consentCreateRequest';
// @ts-ignore
import { ConsentCreateResponse } from '../model/consentCreateResponse';
// @ts-ignore
import { ConsentFraudHistory } from '../model/consentFraudHistory';
// @ts-ignore
import { ConsentFraudStatusRequest } from '../model/consentFraudStatusRequest';
// @ts-ignore
import { ConsentGrantRequest } from '../model/consentGrantRequest';
// @ts-ignore
import { ConsentGrantResponse } from '../model/consentGrantResponse';
// @ts-ignore
import { ConsentQuery200ResponseInner } from '../model/consentQuery200ResponseInner';
// @ts-ignore
import { ConsentStepUpAuthRequest } from '../model/consentStepUpAuthRequest';
// @ts-ignore
import { Error1 } from '../model/error1';
// @ts-ignore
import { GetConsent200Response } from '../model/getConsent200Response';
// @ts-ignore
import { ShippingCostRequest } from '../model/shippingCostRequest';
// @ts-ignore
import { ShippingCostResponse } from '../model/shippingCostResponse';
// @ts-ignore
import { TransactionDataRequest } from '../model/transactionDataRequest';
// @ts-ignore
import { TransactionDataResponse } from '../model/transactionDataResponse';
// @ts-ignore
import { UserProfileInfoForConsentGrant } from '../model/userProfileInfoForConsentGrant';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { ConsentManagementServiceInterface } from './consentManagement.serviceInterface';

@Injectable({
  providedIn: 'root',
})
export class ConsentManagementService implements ConsentManagementServiceInterface {
  protected basePath = 'https://pbb.beta.interac.ca/pbb/v1';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Request to cancel a Payment-Consent (by FI or Merchant).
   * Request to cancel a Payment-Consent, the status will be updated to CANCELLED.
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param consentCancelRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cancelConsent(
    paymentConsentId: string,
    consentCancelRequest: ConsentCancelRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public cancelConsent(
    paymentConsentId: string,
    consentCancelRequest: ConsentCancelRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public cancelConsent(
    paymentConsentId: string,
    consentCancelRequest: ConsentCancelRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public cancelConsent(
    paymentConsentId: string,
    consentCancelRequest: ConsentCancelRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error('Required parameter paymentConsentId was null or undefined when calling cancelConsent.');
    }
    if (consentCancelRequest === null || consentCancelRequest === undefined) {
      throw new Error('Required parameter consentCancelRequest was null or undefined when calling cancelConsent.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/cancel`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: consentCancelRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Request to update fraud status (mark or unmark) on a Payment-Consent (by FI).
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param consentFraudStatusRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consentFraudStatusUpdate(
    paymentConsentId: string,
    consentFraudStatusRequest?: ConsentFraudStatusRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any>;
  public consentFraudStatusUpdate(
    paymentConsentId: string,
    consentFraudStatusRequest?: ConsentFraudStatusRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public consentFraudStatusUpdate(
    paymentConsentId: string,
    consentFraudStatusRequest?: ConsentFraudStatusRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public consentFraudStatusUpdate(
    paymentConsentId: string,
    consentFraudStatusRequest?: ConsentFraudStatusRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error(
        'Required parameter paymentConsentId was null or undefined when calling consentFraudStatusUpdate.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/fraud-status`;
    return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: consentFraudStatusRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Submit user grant on Payment-Consent and requesting for authorization (by KONEK UI).
   * Submit user grant on Payment-Consent which leads to FI\&#39;s Consent authorization and tokenization.
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param consentGrantRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consentGrant(
    paymentConsentId: string,
    consentGrantRequest: ConsentGrantRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ConsentGrantResponse>;
  public consentGrant(
    paymentConsentId: string,
    consentGrantRequest: ConsentGrantRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ConsentGrantResponse>>;
  public consentGrant(
    paymentConsentId: string,
    consentGrantRequest: ConsentGrantRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ConsentGrantResponse>>;
  public consentGrant(
    paymentConsentId: string,
    consentGrantRequest: ConsentGrantRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error('Required parameter paymentConsentId was null or undefined when calling consentGrant.');
    }
    if (consentGrantRequest === null || consentGrantRequest === undefined) {
      throw new Error('Required parameter consentGrantRequest was null or undefined when calling consentGrant.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/grant`;
    return this.httpClient.request<ConsentGrantResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: consentGrantRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Query Payment-Consents (by FI or Portal)
   * Query Payment-Consents using given parameters. The response payload is determined using the access-token in the request.
   * @param fiUserRef Uniquely identifying value of the FI customer, copied from ID-Token.sub claim provided by FI.
   * @param authRef Authorization reference provided during Consent Authorization request
   * @param startDate Specify start date/time of the range in UTC to search for consents.
   * @param endDate Specify end  date/time of the range in UTC to search for consents.
   * @param offset offset is starting point of contacts filter; if offset is not provided it would be defaulted to zero;
   * @param maxResponseItems Maximum number of response items to be returned. All items are returned if this field is absent.
   * @param paymentConsentStatus The Payment consent status in KONEK.  If not provided, default the filter to the following statuses only  * GRANTED * APPROVED          * TOKENIZED
   * @param storeId An identifier of the store
   * @param merchantOrderRef A unique value to identify the order or cart
   * @param maxAmount Specify the maximum amount range for the payment consents.Needs to be used with query parameter min_amount
   * @param minAmount Specify the minimum amount range for the payment consents. Needs to be used with query parameter max_amount
   * @param addressPostalCode Postal or Zip code. Required for Canadian and US addresses.
   * @param merchantId Unique Merchant ID assigned by PayByBank during merchant onboarding
   * @param fiId Unique FI ID assigned by PayByBank during FI onboarding
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consentQuery(
    fiUserRef?: string,
    authRef?: string,
    startDate?: string,
    endDate?: string,
    offset?: number,
    maxResponseItems?: number,
    paymentConsentStatus?:
      | 'GRANTED'
      | 'APPROVED'
      | 'TOKENIZED'
      | 'REQUESTED'
      | 'STEPUP'
      | 'DECLINED'
      | 'DENIED'
      | 'REVOKED',
    storeId?: string,
    merchantOrderRef?: string,
    maxAmount?: number,
    minAmount?: number,
    addressPostalCode?: string,
    merchantId?: string,
    fiId?: string,
    paymentConsentId?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Array<ConsentQuery200ResponseInner>>;
  public consentQuery(
    fiUserRef?: string,
    authRef?: string,
    startDate?: string,
    endDate?: string,
    offset?: number,
    maxResponseItems?: number,
    paymentConsentStatus?:
      | 'GRANTED'
      | 'APPROVED'
      | 'TOKENIZED'
      | 'REQUESTED'
      | 'STEPUP'
      | 'DECLINED'
      | 'DENIED'
      | 'REVOKED',
    storeId?: string,
    merchantOrderRef?: string,
    maxAmount?: number,
    minAmount?: number,
    addressPostalCode?: string,
    merchantId?: string,
    fiId?: string,
    paymentConsentId?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<ConsentQuery200ResponseInner>>>;
  public consentQuery(
    fiUserRef?: string,
    authRef?: string,
    startDate?: string,
    endDate?: string,
    offset?: number,
    maxResponseItems?: number,
    paymentConsentStatus?:
      | 'GRANTED'
      | 'APPROVED'
      | 'TOKENIZED'
      | 'REQUESTED'
      | 'STEPUP'
      | 'DECLINED'
      | 'DENIED'
      | 'REVOKED',
    storeId?: string,
    merchantOrderRef?: string,
    maxAmount?: number,
    minAmount?: number,
    addressPostalCode?: string,
    merchantId?: string,
    fiId?: string,
    paymentConsentId?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<ConsentQuery200ResponseInner>>>;
  public consentQuery(
    fiUserRef?: string,
    authRef?: string,
    startDate?: string,
    endDate?: string,
    offset?: number,
    maxResponseItems?: number,
    paymentConsentStatus?:
      | 'GRANTED'
      | 'APPROVED'
      | 'TOKENIZED'
      | 'REQUESTED'
      | 'STEPUP'
      | 'DECLINED'
      | 'DENIED'
      | 'REVOKED',
    storeId?: string,
    merchantOrderRef?: string,
    maxAmount?: number,
    minAmount?: number,
    addressPostalCode?: string,
    merchantId?: string,
    fiId?: string,
    paymentConsentId?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (fiUserRef !== undefined && fiUserRef !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiUserRef, 'fi_user_ref');
    }
    if (authRef !== undefined && authRef !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>authRef, 'auth_ref');
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'start_date');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'end_date');
    }
    if (offset !== undefined && offset !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>offset, 'offset');
    }
    if (maxResponseItems !== undefined && maxResponseItems !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>maxResponseItems,
        'max_response_items'
      );
    }
    if (paymentConsentStatus !== undefined && paymentConsentStatus !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>paymentConsentStatus,
        'payment_consent_status'
      );
    }
    if (storeId !== undefined && storeId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>storeId, 'store_id');
    }
    if (merchantOrderRef !== undefined && merchantOrderRef !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>merchantOrderRef,
        'merchant_order_ref'
      );
    }
    if (maxAmount !== undefined && maxAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>maxAmount, 'max_amount');
    }
    if (minAmount !== undefined && minAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>minAmount, 'min_amount');
    }
    if (addressPostalCode !== undefined && addressPostalCode !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>addressPostalCode,
        'address_postal_code'
      );
    }
    if (merchantId !== undefined && merchantId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>merchantId, 'merchant_id');
    }
    if (fiId !== undefined && fiId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiId, 'fi_id');
    }
    if (paymentConsentId !== undefined && paymentConsentId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>paymentConsentId,
        'payment_consent_id'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = '/v1/payment-consents';
    return this.httpClient.request<Array<ConsentQuery200ResponseInner>>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Re-submit Payment-Consent for authorization after Step-Up process (by KONEK UI).
   * Re-submit payment_consent_id for FI authorization after STEP-UP process.
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param consentStepUpAuthRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consentStepUpAuth(
    paymentConsentId: string,
    consentStepUpAuthRequest: ConsentStepUpAuthRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ConsentGrantResponse>;
  public consentStepUpAuth(
    paymentConsentId: string,
    consentStepUpAuthRequest: ConsentStepUpAuthRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ConsentGrantResponse>>;
  public consentStepUpAuth(
    paymentConsentId: string,
    consentStepUpAuthRequest: ConsentStepUpAuthRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ConsentGrantResponse>>;
  public consentStepUpAuth(
    paymentConsentId: string,
    consentStepUpAuthRequest: ConsentStepUpAuthRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error('Required parameter paymentConsentId was null or undefined when calling consentStepUpAuth.');
    }
    if (consentStepUpAuthRequest === null || consentStepUpAuthRequest === undefined) {
      throw new Error(
        'Required parameter consentStepUpAuthRequest was null or undefined when calling consentStepUpAuth.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/step-up`;
    return this.httpClient.request<ConsentGrantResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: consentStepUpAuthRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Request for Transaction-Data (by Merchant).
   * Retrieve payment token and cryptogram for a transaction context. The transaction data is used by merchant to build a payment authorization request.
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param transactionDataRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public consentTransactionData(
    paymentConsentId: string,
    transactionDataRequest?: TransactionDataRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<TransactionDataResponse>;
  public consentTransactionData(
    paymentConsentId: string,
    transactionDataRequest?: TransactionDataRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<TransactionDataResponse>>;
  public consentTransactionData(
    paymentConsentId: string,
    transactionDataRequest?: TransactionDataRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<TransactionDataResponse>>;
  public consentTransactionData(
    paymentConsentId: string,
    transactionDataRequest?: TransactionDataRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error('Required parameter paymentConsentId was null or undefined when calling consentTransactionData.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/transaction-data`;
    return this.httpClient.request<TransactionDataResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: transactionDataRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Request to create Payment-Consent for a check-out (by Merchant SDK)
   * Request to create Payment-Consent for a check-out
   * @param consentCreateRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createConsent(
    consentCreateRequest?: ConsentCreateRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ConsentCreateResponse>;
  public createConsent(
    consentCreateRequest?: ConsentCreateRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ConsentCreateResponse>>;
  public createConsent(
    consentCreateRequest?: ConsentCreateRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ConsentCreateResponse>>;
  public createConsent(
    consentCreateRequest?: ConsentCreateRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = '/v1/payment-consents';
    return this.httpClient.request<ConsentCreateResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: consentCreateRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get a Payment-Consent (by FI, Merchant or Portal).
   * Get Payment-Consents using given parameters. The response payload is determined using the access-token in the request.
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConsent(
    paymentConsentId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<GetConsent200Response>;
  public getConsent(
    paymentConsentId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<GetConsent200Response>>;
  public getConsent(
    paymentConsentId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<GetConsent200Response>>;
  public getConsent(
    paymentConsentId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error('Required parameter paymentConsentId was null or undefined when calling getConsent.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<GetConsent200Response>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Get fraud history for a specified payment consent (Portal).
   * Get Payment-Consent Fraud history .
   * @param paymentConsentId Unique identifier of the Payment Consent within KONEK. Assigned by KONEK.
   * @param xKCiamId CIAM issued id-token for the user authenticated in Portal.    This header is required for RBAC when the bearer token scope is &#x60;portal&#x60;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFraudHistory(
    paymentConsentId: string,
    xKCiamId: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Array<ConsentFraudHistory>>;
  public getFraudHistory(
    paymentConsentId: string,
    xKCiamId: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Array<ConsentFraudHistory>>>;
  public getFraudHistory(
    paymentConsentId: string,
    xKCiamId: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Array<ConsentFraudHistory>>>;
  public getFraudHistory(
    paymentConsentId: string,
    xKCiamId: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error('Required parameter paymentConsentId was null or undefined when calling getFraudHistory.');
    }
    if (xKCiamId === null || xKCiamId === undefined) {
      throw new Error('Required parameter xKCiamId was null or undefined when calling getFraudHistory.');
    }

    let localVarHeaders = this.defaultHeaders;
    if (xKCiamId !== undefined && xKCiamId !== null) {
      localVarHeaders = localVarHeaders.set('x-k-ciam-id', String(xKCiamId));
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/payment-consents/${this.configuration.encodeParam({
      name: 'paymentConsentId',
      value: paymentConsentId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/fraud-history`;
    return this.httpClient.request<Array<ConsentFraudHistory>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Retrieve User-Profile-Info for the Consent-Grant process
   * Retrieve user-profile-info for the Consent-Grant process.   ___   ### NOTE: This API is for inter-service use only.
   * @param userProfileRef A unique reference identifier of the user
   * @param paymentConsentId Identifier of payment-consent
   * @param fiId FI identifier (requester_id) of the selected account or card for the checkout
   * @param fiUserId KONEK internal identifier (mapped to fi_user_ref) of the selected payment method\&#39;s cardholder or accountholder
   * @param shippingAddressRef A unique reference identifier of the shipping address
   * @param fiAccountRef FI provided identifier of the account or card selected for the checkout
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getUserInfoForConsentGrant(
    userProfileRef: string,
    paymentConsentId: string,
    fiId: string,
    fiUserId: string,
    shippingAddressRef?: string,
    fiAccountRef?: string,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<UserProfileInfoForConsentGrant>;
  public getUserInfoForConsentGrant(
    userProfileRef: string,
    paymentConsentId: string,
    fiId: string,
    fiUserId: string,
    shippingAddressRef?: string,
    fiAccountRef?: string,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<UserProfileInfoForConsentGrant>>;
  public getUserInfoForConsentGrant(
    userProfileRef: string,
    paymentConsentId: string,
    fiId: string,
    fiUserId: string,
    shippingAddressRef?: string,
    fiAccountRef?: string,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<UserProfileInfoForConsentGrant>>;
  public getUserInfoForConsentGrant(
    userProfileRef: string,
    paymentConsentId: string,
    fiId: string,
    fiUserId: string,
    shippingAddressRef?: string,
    fiAccountRef?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (userProfileRef === null || userProfileRef === undefined) {
      throw new Error(
        'Required parameter userProfileRef was null or undefined when calling getUserInfoForConsentGrant.'
      );
    }
    if (paymentConsentId === null || paymentConsentId === undefined) {
      throw new Error(
        'Required parameter paymentConsentId was null or undefined when calling getUserInfoForConsentGrant.'
      );
    }
    if (fiId === null || fiId === undefined) {
      throw new Error('Required parameter fiId was null or undefined when calling getUserInfoForConsentGrant.');
    }
    if (fiUserId === null || fiUserId === undefined) {
      throw new Error('Required parameter fiUserId was null or undefined when calling getUserInfoForConsentGrant.');
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (paymentConsentId !== undefined && paymentConsentId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>paymentConsentId,
        'payment_consent_id'
      );
    }
    if (fiId !== undefined && fiId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiId, 'fi_id');
    }
    if (fiUserId !== undefined && fiUserId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiUserId, 'fi_user_id');
    }
    if (shippingAddressRef !== undefined && shippingAddressRef !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>shippingAddressRef,
        'shipping_address_ref'
      );
    }
    if (fiAccountRef !== undefined && fiAccountRef !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fiAccountRef, 'fi_account_ref');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/v1/user-profiles/${this.configuration.encodeParam({
      name: 'userProfileRef',
      value: userProfileRef,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}/consent-grant`;
    return this.httpClient.request<UserProfileInfoForConsentGrant>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Calculate shipping cost (by KONEK UI)
   * KONEK UI call this API to calculate shipping cost. Note that when this API is implemented , it should be using payment_consent_id path parameter
   * @param shippingCostRequest
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   * @deprecated
   */
  public shippingCost(
    shippingCostRequest?: ShippingCostRequest,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ShippingCostResponse>;
  public shippingCost(
    shippingCostRequest?: ShippingCostRequest,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ShippingCostResponse>>;
  public shippingCost(
    shippingCostRequest?: ShippingCostRequest,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ShippingCostResponse>>;
  public shippingCost(
    shippingCostRequest?: ShippingCostRequest,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = '/v1/payment-consents/shipping-cost';
    return this.httpClient.request<ShippingCostResponse>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: shippingCostRequest,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
