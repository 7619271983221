/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.0
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User registration status
 */
export type UserRegistrationStatus = 'REGISTERED' | 'STAGED';

export const UserRegistrationStatus = {
  Registered: 'REGISTERED' as UserRegistrationStatus,
  Staged: 'STAGED' as UserRegistrationStatus,
};
