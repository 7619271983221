import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { AccountType, ConsentGrantRequest, ShippingAddress, UserAccountInfo, UserRegistrationStatus } from '@api/index';
import { RecurringInfo } from '@api/model/recurringInfo';
import { NOTICE_TYPE } from '@core/constants';
import { IOIDC_ERROR, OIDC_ERROR, PAYMENT_METHOD_CHECK_ERRORS } from '@core/constants/error-code';
import { SNAME } from '@core/constants/storage';
import { KEEP_ALIVE_STAGED_PROFILE_ERROR } from '@core/interceptors/helper';
import { STORED_LANGUAGE_KEY } from '@core/services/language.service';
import { setShippingAddress } from '@store/checkout/checkout.actions';
import { LoaderState } from '@store/core/core.interface';
import { selectOIDCError } from '@store/core/core.selectors';
import { UserActions } from '@store/user/user.actions';
import { IUserProfile, PaymentMethodCheckStatus } from '@store/user/user.interface';
import { map, Observable, pairwise } from 'rxjs';
import { checkPostalCodeFormat } from './validators/validators';

const EMAIL_REGEXP = /^[\S]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;

export const sortAlphabetic = (arr: any[], key: string): any[] => {
  return arr.sort((a, b) => {
    if (key) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    } else {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }
  });
};

export const loaderReducerState = (res: any, state: string, error?: any): LoaderState<any> => {
  switch (state) {
    case 'get':
    case 'start':
      return {
        data: res,
        isLoading: true,
        error: null,
      };
    case 'success':
      return {
        data: res,
        isLoading: false,
        error: null,
      };
    case 'error':
      return {
        data: res,
        isLoading: false,
        error,
      };
    default:
      return {
        data: null,
        isLoading: false,
        error: null,
      };
  }
};

export const isMobile = (): boolean =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && screen.availWidth < 768;

export const setCookie = (name: string, value: any, days: number, session?: boolean) => {
  let expires = '';
  let date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  expires = session ? '' : '; expires=' + date.toUTCString();
  expires += '; domain=' + location.hostname.replace('ui.', '.');
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (name: string) => {
  let nameEQ = name + '=';
  let ca = document.cookie.split(';');
  for (let cookie of ca) {
    let c = cookie;
    while (c.startsWith(' ')) c = c.substring(1, c.length);
    if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const ordinal = (n: number | undefined): string => {
  if (n) {
    if (n === 1) {
      return '';
    }
    let s = ['th', 'st', 'nd', 'rd'];
    let v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }
  return '';
};

export const ordinalDayOfTheWeek = (n: string): string => {
  if (n === RecurringInfo.WeekOfMonthEnum.Last) {
    return 'last';
  }
  return ordinal(WEEKDAY[n]);
};

export const WEEKDAY: { [key: string]: number } = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
};

export const getWeekDayDate = (dayOftheWeek: string | undefined, startDate: string | undefined) => {
  if (!dayOftheWeek) {
    return new Date(startDate || '');
  }
  return new Date(weekDayDate[dayOftheWeek]);
};

export const weekDayDate: { [key: string]: string } = {
  SUN: '1/1/1984',
  MON: '1/2/1984',
  TUE: '1/3/1984',
  WED: '1/4/1984',
  THU: '1/5/1984',
  FRI: '1/6/1984',
  SAT: '1/7/1984',
};

export const isObject = (obj: any) => obj && typeof obj === 'object';
export const minsToDays = (mins: number) => mins / (24 * 60 * 60 * 1000);
export const getLocale = (): string => JSON.parse(localStorage.getItem(STORED_LANGUAGE_KEY) || '"en-CA"');
export const mergeDeep = (...objects: any[]) => {
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = oVal;
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else if (oVal === null) {
        prev[key] = null;
      } else {
        prev[key] = oVal !== undefined ? oVal : prev[key];
      }
    });

    return prev;
  }, {});
};

export const cleanFormValue = (value: string) => {
  if (typeof value === 'undefined' || value.trim() === '') return ' ';
  else return value;
};

export const isEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const isCheckout = (): boolean => {
  return window.location.pathname.includes('checkout');
};

export const isPaymentMethodPage = (pathName: string): boolean => {
  return pathName.includes('paymentMethod') || pathName.includes('managePaymentMethods');
};

export const ischeckoutPath = (pathName: string): boolean => {
  return pathName.includes('checkout');
};

export const getDeviceConfigFlags = (): { webauthn: boolean; trusted: boolean } =>
  JSON.parse(sessionStorage.getItem(SNAME.WEBAUTHNTRUSTED) || '{ "webauthn": false, "trusted": false }');

export function errorOnSame<T>(s: Observable<T>) {
  return new Observable((obs) => {
    s.pipe(pairwise()).subscribe(([a, b]) => {
      if (a === b) {
        obs.error(b);
      }
    });
  });
}

export function errorOnStaged(s: Observable<string>) {
  return new Observable((obs) => {
    s.subscribe((status: string) => {
      if (status === UserRegistrationStatus.Staged) {
        obs.error(KEEP_ALIVE_STAGED_PROFILE_ERROR);
      }
    });
  });
}

export const isDateBefore = (date: string, minutes: number) => {
  if (!date) {
    return false;
  }
  const testDate = new Date(new Date(date).getTime() + minutes * 60000);
  return testDate > new Date();
};

export const getLowestRoute = (snapShot: ActivatedRoute): ActivatedRoute => {
  if (snapShot?.children.length > 0 && snapShot.firstChild) {
    return getLowestRoute(snapShot.firstChild);
  }
  return snapShot;
};

export const getLowestRouteSnapshot = (snapShot: ActivatedRouteSnapshot): ActivatedRouteSnapshot => {
  if (snapShot?.children.length > 0 && snapShot.firstChild) {
    return getLowestRouteSnapshot(snapShot.firstChild);
  }
  return snapShot;
};
export const OidcErrorHandlerMixin = <T extends abstract new (...args: any[]) => any>(c: T) => {
  abstract class OidcErrorHandler extends c {
    oidcerror$: Observable<IOIDC_ERROR | null>;
    noticeType = NOTICE_TYPE;
    constructor(...args: any[]) {
      super(...args);
      this.oidcerror$ = this['store'].select(selectOIDCError).pipe(
        map((key: string) => {
          return key ? OIDC_ERROR[key] : null;
        })
      );
    }
  }

  return OidcErrorHandler;
};

export const setWebauthnTrustedFlags = (trusted: boolean, webauthn: boolean) => {
  sessionStorage.setItem(SNAME.WEBAUTHNTRUSTED, JSON.stringify({ trusted, webauthn }));
};

export const getSessionDeviceIdKeyName = (userRef: string) => `${SNAME.DEVICE_ID}_${userRef}`;

export const checkAddressValiditiy = (selectedAddress: ShippingAddress) => {
  return (
    !!selectedAddress.postal_code &&
    !!checkPostalCodeFormat(selectedAddress.postal_code) &&
    !!selectedAddress.recipient_name &&
    !!(!selectedAddress.recipient_name || selectedAddress.recipient_name.length <= 100) &&
    !!selectedAddress.country &&
    (!!selectedAddress.street_name || !!selectedAddress.rural_postal_address) &&
    !!(!selectedAddress.city || selectedAddress.city.length <= 50) &&
    !!(!selectedAddress.province || selectedAddress.province.length <= 20) &&
    !!(!selectedAddress.apartment || selectedAddress.apartment.length <= 20) &&
    !!(!selectedAddress.street_number || selectedAddress.street_number.length <= 20) &&
    !!(!selectedAddress.street_name || selectedAddress.street_name.length <= 100) &&
    !!(!selectedAddress.rural_postal_address || selectedAddress.rural_postal_address.length <= 100)
  );
};
export const checkPersonalInformationValiditiy = (userProfile?: IUserProfile) => {
  return userProfile
    ? !!userProfile.first_name &&
        userProfile.first_name.length <= 50 &&
        !!userProfile.last_name &&
        userProfile.last_name.length <= 50 &&
        !!userProfile.email &&
        EMAIL_REGEXP.test(userProfile.email) &&
        userProfile.email.length <= 50 &&
        !!(userProfile.phone_number
          ? userProfile.phone_number && userProfile.phone_number.length >= 10 && userProfile.phone_number.length <= 30
          : true)
    : false;
};

export const htmlDecode = (str: any) => {
  const div = document.createElement('div');
  div.innerHTML = str;
  return div.textContent || div.innerText;
};

// export const hasFormValueChanged = (initialFormValue: any, form: FormGroup) => {
//   return Object.keys(initialFormValue).some((key) => form.value[key] != initialFormValue[key]);
// };
export const hasFormValueChanged = (initialFormValue: any, form: FormGroup, containFields?: any) => {
  if (containFields) {
    const ArrFields = Object.keys(initialFormValue).filter((key) => form.value[key] != initialFormValue[key] && key);
    return ArrFields.some((o) => containFields.includes(o));
  }
  return Object.keys(initialFormValue).some((key) => form.value[key] != initialFormValue[key]);
};

export const noTrimDataKeys = ['street_number', 'apartment'];
export const trimString = (data: any) => {
  if (typeof data === 'string') {
    return data.trim();
  }
  if (typeof data === 'object') {
    Object.keys(data).forEach((k) => {
      if (k && !noTrimDataKeys.includes(k)) {
        data[k] = trimString(data[k]);
      }
    });
  }
  return data;
};

export const omitEmptyFields = (data: any): any => {
  let res: any = {};
  if (isObject(data)) {
    Object.keys(data).forEach((k) => {
      if (isObject(data[k])) {
        res[k] = omitEmptyFields(data[k]);
      }
      if (data[k]) {
        res[k] = data[k];
      }
      return res;
    });
  }
  return res;
};

export const getLinkedFIId = (userProfile: IUserProfile | undefined) => {
  return userProfile?.linked_fi_info && userProfile?.linked_fi_info.length > 0
    ? userProfile.linked_fi_info[0].linked_fi_id || ''
    : '';
};

export const getFIUserId = (userProfile: IUserProfile | undefined) => {
  return (
    userProfile?.linked_fi_info.find(
      (fi) =>
        fi.linked_fi_id === userProfile.default_linked_fi_id &&
        fi.accounts?.some((account) => account.fi_account_ref === userProfile.default_fi_account_ref)
    )?.fi_user_id || ''
  );
};

export const getShippingAddressAction = (
  shippingRequired: boolean,
  shippingAddress: ShippingAddress | undefined,
  userProfile: IUserProfile | undefined
) => {
  return shippingRequired && shippingAddress
    ? setShippingAddress({
        shippingAddress,
        update: shippingRequired,
      })
    : UserActions.setUserShippingAddressRef({
        shippingAddressRef: userProfile?.shipping_addresses?.[0].shipping_address_ref!,
      });
};

export const getSelectedShippingAddress = (
  userProfile: IUserProfile | undefined,
  consentGrantPayload: ConsentGrantRequest
): ShippingAddress | undefined => {
  if (
    !userProfile?.shipping_addresses?.some(
      (address) => address.shipping_address_ref === consentGrantPayload.shipping_address?.shipping_address_ref
    )
  ) {
    const defaultShippingAddress = userProfile?.shipping_addresses?.find(
      (address) => address.shipping_address_ref === userProfile.default_shipping_address_ref
    );
    return defaultShippingAddress || userProfile?.shipping_addresses?.[0];
  }

  return consentGrantPayload?.shipping_address;
};

export const checkUserPaymentMethods = (
  userAccounts: Array<UserAccountInfo>,
  merchantSupportPaymentMethods?: Array<AccountType>,
  userProfileDefaultAccountRef?: string,
  userConsentAccountRef?: string
): PaymentMethodCheckStatus => {
  const checkStatus: PaymentMethodCheckStatus = {
    [PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_PAYMENT_METHOD]: true,
    [PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_CONSENT_PAYMENT_METHOD]: true,
    [PAYMENT_METHOD_CHECK_ERRORS.USER_PAYMENT_METHOD]: true,
    [PAYMENT_METHOD_CHECK_ERRORS.USER_DEFAULT_PAYMENT_METHOD]: true,
    [PAYMENT_METHOD_CHECK_ERRORS.USER_CONSENT_PAYMENT_METHOD]: true,
  };

  if (!userProfileDefaultAccountRef) {
    checkStatus[PAYMENT_METHOD_CHECK_ERRORS.USER_DEFAULT_PAYMENT_METHOD] = false;
  }

  if (!userConsentAccountRef) {
    checkStatus[PAYMENT_METHOD_CHECK_ERRORS.USER_CONSENT_PAYMENT_METHOD] = false;
    checkStatus[PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_CONSENT_PAYMENT_METHOD] = false;
  }

  if (!merchantSupportPaymentMethods) {
    checkStatus[PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_PAYMENT_METHOD] = false;
  }

  userAccounts.forEach((account) => {
    if (
      checkStatus[PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_PAYMENT_METHOD] &&
      merchantSupportPaymentMethods?.includes(account.account_type)
    ) {
      checkStatus[PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_PAYMENT_METHOD] = false;
    }
    if (checkStatus[PAYMENT_METHOD_CHECK_ERRORS.USER_PAYMENT_METHOD] && !account.disable_reason) {
      checkStatus[PAYMENT_METHOD_CHECK_ERRORS.USER_PAYMENT_METHOD] = false;
    }

    if (userConsentAccountRef === account.fi_account_ref) {
      checkStatus[PAYMENT_METHOD_CHECK_ERRORS.USER_CONSENT_PAYMENT_METHOD] = !!account.disable_reason;
      checkStatus[PAYMENT_METHOD_CHECK_ERRORS.MERCHANT_SUPPORT_CONSENT_PAYMENT_METHOD] =
        !merchantSupportPaymentMethods?.includes(account.account_type);
    }

    if (userProfileDefaultAccountRef === account.fi_account_ref && !account.disable_reason) {
      checkStatus[PAYMENT_METHOD_CHECK_ERRORS.USER_DEFAULT_PAYMENT_METHOD] = false;
    }
  });

  return checkStatus;
};
