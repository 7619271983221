/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.0
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Specifies the method used to authenticate the user, valid values are;   `FI_AUTH` - Customer is authenticated by FI   `PARTNER_FI_AUTH` - Customer is authenticated by Partner FI   `USER_SENSED_BIOMETRIC`-  Customer is authenticated by KONEK   `USER_SENSED_PASSCODE`- *DEPRECATED* Customer is authenticated using WebAuthN Passcode
 */
export type UserAuthMethod = 'FI_AUTH' | 'PARTNER_FI_AUTH' | 'USER_SENSED_BIOMETRIC' | 'USER_SENSED_PASSCODE';

export const UserAuthMethod = {
  FiAuth: 'FI_AUTH' as UserAuthMethod,
  PartnerFiAuth: 'PARTNER_FI_AUTH' as UserAuthMethod,
  UserSensedBiometric: 'USER_SENSED_BIOMETRIC' as UserAuthMethod,
  UserSensedPasscode: 'USER_SENSED_PASSCODE' as UserAuthMethod,
};
